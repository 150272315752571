import React, {useState, useRef} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {CircularProgress} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {useNavigate, useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PasswordIcon from '@mui/icons-material/Password';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import PageContainer from 'components/elements/PageContainer';
import {ROUTE_USERS} from 'pages/constants';
import Divider from 'components/elements/Divider';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import {useViewport} from 'hooks/useViewport';
import useUser from 'hooks/users/use-user';
import useUserUpdate from 'hooks/users/use-user-update';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';

import UserForm from './user-form';
import SetPasswordDialog from './set-password-dialog';
import ManageRolesDialog from "./manage-roles-dialog";

const ViewUser = () => {
    const {t} = useTranslation();
    const {userId} = useParams();
    const {height} = useViewport();

    const {data, isLoading} = useUser({userId});

    if (isLoading) {
        return <Grid container justifyContent='center' alignItems='center' sx={{width: '100%', height: height - 160}}>
            <Grid item>
                <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70}/>
            </Grid>
        </Grid>;
    }

    if (!data) {
        return <>No data</>;
    }

    return (
        <PageContainer pageTitle={t(data.data.first_name)}>
            <ViewOrganizationPage data={data.data}/>
        </PageContainer>
    );
};

const ViewOrganizationPage = ({data}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();

    const {mutateAsync, status} = useUserUpdate();

    const [passwordUpdating, setPasswordUpdating] = useState(null);
    const [rolesUpdating, setRolesUpdating] = useState(null);

    const navigateToUsers = () => {
        navigate(ROUTE_USERS);
    };

    const onFormSubmit = (values) => {
        const req = {
            first_name: values.first_name,
            last_name: values.last_name,
            other_name: values.other_name,
            name_initials: values.name_initials,
            email: values.email,
            contact_number: values.contact_number,
            role: values.role,
        };
        mutateAsync({userId: data.user_id, orgId: data.organization_id, req}).then(() => {
            navigateToUsers();
        });
    };

    const handleUpdate = () => {
        formRef.current.handleSubmit();
    };

    const handleSetPassword = () => {
        setPasswordUpdating(data.user_id);
    };

    const onCloseResetPassword = () => {
        setPasswordUpdating(null);
    }

    const handleManageRoles = () => {
        setRolesUpdating(data.user_id);
    };

    const onCloseManageRoles = () => {
        setRolesUpdating(null);
    }

    return (
        <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>

            <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item xs={6}>
                    <Grid
                        item
                        container
                        alignItems='center'
                        width='unset'
                        sx={{cursor: 'pointer'}}
                        onClick={navigateToUsers}
                    >
                        <Grid item>
                            <Typography>Users</Typography>
                        </Grid>
                        <Grid item sx={{pl: '0.3em', '&.MuiGrid-root': {display: 'flex'}}}>
                            <OpenInNewIcon/>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item container xs={6} justifyContent='flex-end' spacing={2}>
                    <Grid item>
                        <Tooltip title={t('feature.users.button.manageRoles.tooltip')}>
                            <Button startIcon={<AdminPanelSettingsIcon/>}
                                    variant={'contained'}
                                    disabled={status==='loading'}
                                    onClick={() => handleManageRoles()}
                            >
                                {t('feature.users.button.manageRoles')}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('feature.users.button.setPassword.tooltip')}>
                            <Button startIcon={<PasswordIcon/>}
                                    variant={'contained'}
                                    disabled={status==='loading'}
                                    onClick={() => handleSetPassword()}
                            >
                                {t('feature.users.button.setPassword')}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('feature.organizations.button.save.tooltip')}>
                            <Button startIcon={<SaveOutlinedIcon/>}
                                    variant={'contained'}
                                    disabled={status==='loading'}
                                    onClick={() => handleUpdate()}
                            >
                                {status === 'loading' ? (
                                        <CircularProgress
                                            size={24}
                                        />
                                    ) :
                                    t('feature.organizations.button.save')}
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Divider/>
            </Grid>

            <Grid item container justifyContent={'center'} spacing={2}>
                <Grid item container md={12} lg={9}>
                    <UserForm formRef={formRef} onUpload={onFormSubmit} data={data}/>
                </Grid>
            </Grid>

            <SetPasswordDialog open={passwordUpdating} onClose={onCloseResetPassword}/>
            <>{rolesUpdating && <ManageRolesDialog open={rolesUpdating} onClose={onCloseManageRoles}/>}</>

        </Grid>
    );
};

export default ViewUser;