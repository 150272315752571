import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import styled from '@mui/system/styled';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import {Routes, pathToIndex} from 'pages/constants';
import CompanyLogo from 'components/base/CompanyLogo';

const drawerMixin = (theme) => ({
    width: theme.cmlInsights.drawer.openWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
});

const drawerPaperMixin = (theme) => ({
    backgroundColor: theme.palette.primary.main,
});

const openMixin = (theme) => ({
    width: theme.cmlInsights.drawer.openWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closeMixin = (theme) => ({
    overflowX: "hidden",
    width: theme.cmlInsights.drawer.closeWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
});

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({drawerOpen, theme}) => ({
    ...drawerMixin(theme),
    ...(drawerOpen && openMixin(theme)),
    ...(!drawerOpen && closeMixin(theme)),
    "& .MuiDrawer-paper": {
        ...drawerPaperMixin(theme),
        ...(drawerOpen && openMixin(theme)),
        ...(!drawerOpen && closeMixin(theme)),
        boxShadow: '3px 0 10px -2px #888',
    },
}));

const Branding = styled("div", {
    shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({drawerOpen, theme}) => ({
    display: "flex",
    alignItems: "center",
    color: "white",
    ...theme.mixins.toolbar,
    cursor: "pointer",
    ...(drawerOpen && {
        padding: theme.spacing(1, 0, 0, 4),
    }),
    ...(!drawerOpen && {
        justifyContent: "center",
    }),
}));

const SubListItemText = styled(MuiListItemText)(() => ({
    '& .MuiTypography-root': {
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        fontWeight: '400',
        color: 'white',
    },
}));

const ListItemIcon = styled(MuiListItemIcon, {
    shouldForwardProp: (prop) => prop !== "active",
})(({theme, active}) => ({
    '&.MuiListItemIcon-root': {minWidth: '2em', color: active ? theme.cmlInsights.drawer.activeItemColor : 'white'},
}));

const SideBar = ({drawerOpen, handleDrawerOpen}) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setActiveTab(pathToIndex[location.pathname.split('/').slice(0, 3).join('/')]);
    }, [location]);

    return (
        <StyledDrawer variant="permanent" anchor="left" drawerOpen={drawerOpen}>
            <Branding drawerOpen={drawerOpen} onClick={handleDrawerOpen}>
                {!drawerOpen && <MenuOutlinedIcon/>}
                {drawerOpen && <CompanyLogo width={'65%'}/>}
            </Branding>

            {drawerOpen && <List sx={{pt: '1em'}}>
                <List component="div" sx={{pt: '0', pb: '1.5em'}}>
                    <>
                        {Routes.map((item, key) => (
                            <MuiListItem sx={{pt: '0', pb: '0'}}>
                                <MuiListItemButton
                                    key={key}
                                    component={NavLink}
                                    to={item.path}
                                    sx={{
                                        py: '0.5em',
                                        pl: '2em',
                                    }}
                                >
                                    <ListItemIcon active={item.id === activeTab}>{item.icon}</ListItemIcon>
                                    <SubListItemText primary={item.name}/>
                                </MuiListItemButton>
                            </MuiListItem>
                        ))}
                    </>
                </List>
            </List>}

            {!drawerOpen && <List sx={{pt: '0'}}>
                <>
                    {Routes.map((item, key) => (
                        <MuiListItem sx={{pt: '0', pb: '0'}}>
                            <MuiListItemButton
                                key={key}
                                component={NavLink}
                                to={item.path}
                                sx={{
                                    py: '1em',
                                    px: 0,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <ListItemIcon active={item.id === activeTab}>{item.icon}</ListItemIcon>
                            </MuiListItemButton>
                        </MuiListItem>
                    ))}
                </>
            </List>}
        </StyledDrawer>
    );
};

export default SideBar;
