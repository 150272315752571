import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from 'components/elements/Divider';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/elements/PageContainer';
import useOrganizations from '../../../hooks/organizations/use-organizations';
import InfiniteLoadList from '../../../components/elements/InfiniteLoadList';

import UsersList from './users-list';
import Filters from './filters';
import { useSelector } from 'react-redux';

const Users = () => {
  const { t } = useTranslation();

  return (
    <PageContainer pageTitle={t('feature.users.pageTitle')}>
      <UserPage />
    </PageContainer>
  );
};

const UserPage = ({ containerHeight }) => {

  const [filters, setFilters] = useState({
    search: '',
    organization_id: ''
  });

  return (
    <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
      <Grid item container>
        <Grid item xs={12}>
          <Filters filters={filters} handleFilter={setFilters} />
        </Grid>
      </Grid>


      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <UsersList height={containerHeight - 100} filters={filters} />
      </Grid>
    </Grid>
  );
};

export default Users;
