import { configureStore } from '@reduxjs/toolkit';

import { reducer as AuthReducer } from './auth';
import { reducer as AppReducer } from './app';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    app: AppReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
