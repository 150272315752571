import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import useUserResetPassword from 'hooks/users/use-user-reset-password';

import SetPasswordForm from './set-password-form';

const SetPasswordDialog = ({open, onClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();
    const {mutateAsync, status} = useUserResetPassword();
    const onSave = () => {
        formRef.current.handleSubmit();
    }

    const onSubmit = (values) => {
        const req = {
            password: values.password,
            temporary: values.temporary
        }
        mutateAsync({userId: open, req}).finally(() => {
            onClose()
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title">
                {t('feature.users.setPassword.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        <Grid item>
                            <SetPasswordForm onSubmit={onSubmit} formRef={formRef}/>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={status==='loading'}>
                    Cancel
                </Button>
                <Button onClick={() => onSave(open)} disabled={status==='loading'}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SetPasswordDialog;