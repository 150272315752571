import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {IconButton} from '@mui/material';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';

import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import InfiniteLoadTable from 'components/elements/InfiniteLoadTable';
import ErrorDataCard from 'components/elements/ErrorCard';
import useOrganizations from 'hooks/organizations/use-organizations';
import {ROUTE_ORGANIZATION_VIEW} from 'pages/constants';
import useOrganizationChangeState from 'hooks/organizations/use-organization-change-state';
import useOrganizationDelete from 'hooks/organizations/use-organization-delete';
import DeleteForm from 'components/elements/DeleteForm';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import useOrganizationRestore from 'hooks/organizations/use-organization-restore';
import LabeledChip from 'components/elements/LabeledChip';
import OrganizationDeleteForm from './organization-delete-form';

const OrganizationsList = ({
                               height,
                               filters = {
                                   search: '',
                               },
                           }) => {
    const {t} = useTranslation();
    const [flattenedOrganizations, setFlattenedOrganizations] = useState([]);
    const navigate = useNavigate();
    const formRef = useRef();
    const restoreFormRef = useRef();

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch} = useOrganizations({
        filters,
    });

    const [organizationDeleting, setOrganizationDeleting] = useState(null);
    const [organizationRestoring, setOrganizationRestoring] = useState(null);

    const {mutateAsync: changeStateAsync} = useOrganizationChangeState();
    const {mutateAsync: deleteAsync, isError: isDeleteError, isLoading: isDeleting} = useOrganizationDelete();
    const {mutateAsync: restoreAsync, isError: isRestoreError, isLoading: isRestoring} = useOrganizationRestore();

    const handleUpdate = (organizationId, organizationName, organizationState) => {

        const req = {
            organizationId,
            organizationState,
        };
        changeStateAsync({req}).finally(() => {
           refetch();
        });
    }

    const handleDelete = (organization) => {
        setOrganizationDeleting(organization);
    };

    const handleRestore = (organization) => {
        setOrganizationRestoring(organization);
    };

    const onFormSubmit = (values) => {
        const req = {
            ...values,
            organizationId: organizationDeleting.organization_id,
        }
        deleteAsync({req}).then(() => {
            setOrganizationDeleting(null);
            refetch().finally();
        });
    };

    const onRestoreFormSubmit = (values) => {
        const req = {
            ...values
        }
        restoreAsync({orgId:organizationRestoring.organization_id,req}).then(() => {
            setOrganizationRestoring(null);
            refetch().finally();
        });
    };

    const onCloseDelete = () => {
        setOrganizationDeleting(null);
    }

    const onCloseRestore = () => {
        setOrganizationRestoring(null);
    }

    const onConfirmDelete = () => {
        formRef.current.handleSubmit();
    }

    const onConfirmRestore = () => {
        restoreFormRef.current.handleSubmit();
    }

    const handleNameClick = (orgId) => {
        navigate(ROUTE_ORGANIZATION_VIEW.replace(':orgId', orgId));
    };

    const getType = (org_type) => {
        if (org_type === 'PDP') {
            return 'Pdp'
        } else if (org_type === 'NON_PDP') {
            return 'Non Pdp'
        } else {
            return ''
        }
    }

    const getAccessLevel = (access_level) => {
        if (access_level === 'FULL') {
            return 'Full'
        } else if (access_level === 'RESTRICTED') {
            return 'Restricted'
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (data) {
            setFlattenedOrganizations(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []),
            );
        }
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                align: 'left',
                width: '20%',
                Cell: ({row: {values, original}}) => {
                    return (
                        <Grid item container alignItems='center' sx={{cursor: 'pointer'}}
                              onClick={() => handleNameClick(original.organization_id)} spacing={1}>
                            <Grid item>  {values.name}</Grid>
                            <Grid item
                                  sx={{pl: '0.3em', '&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                <OpenInNewIcon/>
                            </Grid>
                            {original.status === 'PENDING' &&  <Grid item>
                                <LabeledChip label={"Pending Delete"} title={`Pending delete on ${format(parseISO(original.delete_timestamp), 'Pp')}`} backgroundColor={"#fdeded"} color={"#5f2120"}/>
                            </Grid>}
                        </Grid>
                    );
                },
            },
            {
                Header: 'Type',
                accessor: 'type',
                align: 'left',
                width: '10%',

                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{getType(values.type)}</>;
                },
            },
            {
                Header: 'Access Level',
                accessor: 'access_level',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{getAccessLevel(values.access_level)}</>;
                },
            },
            {
                Header: 'Onboard Date',
                accessor: 'onboard_timestamp',
                align: 'center',
                width: '15%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{format(parseISO(values.onboard_timestamp), 'Pp')}</>;
                },
            },
            {
                accessor: 'state',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {original, values},
                           },
                       }) => {
                    return (
                        <Grid container justifyContent='center' spacing={2} pr={2}>
                            <Grid item
                                  xs={12}
                                  sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center', width: '100%'}}}>
                                {values.state === 'INACTIVE' && (
                                    <Button
                                        fullWidth
                                        size={'small'}
                                        onClick={() => handleUpdate(original.organization_id, values.name, values.state)}
                                        variant='contained'
                                        color='success'
                                    >
                                        Activate
                                    </Button>
                                )}
                                {values.state === 'ACTIVE' && (
                                    <Button
                                        fullWidth
                                        size={'small'}
                                        variant='contained'
                                        onClick={() => handleUpdate(original.organization_id, values.name, values.state)}
                                        color='error'
                                    >
                                        Deactivate
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                    );
                },
            },
            {
                accessor: 'delete',
                align: 'right',
                width: '3%',
                Cell: ({
                           cell: {
                               row: {original, values},
                           },
                       }) => {
                    return (
                        <Grid container spacing={2}>
                            {(original.status === '' ||original.status === 'REVERTED') && <Grid xs={2} item>
                                <Tooltip title={t('feature.organizations.organization.delete.icon')}>
                                    <IconButton
                                        sx={{display: 'flex', alignItems: 'center'}}
                                        color='secondary'
                                        onClick={() => handleDelete(original)}
                                    >
                                        <DeleteOutlineOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }
                            {original.status === 'PENDING' && <Grid xs={2} item>
                                <Tooltip title={t('feature.organizations.organization.restore.icon')}>
                                    <IconButton
                                        sx={{display: 'flex', alignItems: 'center'}}
                                        color='secondary'
                                        onClick={() => handleRestore(original)}
                                    >
                                        <RestoreOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            }
                        </Grid>
                    );
                },
            },
        ],
        [t],
    );

    if (status === 'loading') {
        return <>Loading...</>;
    }

    if (isError) {
        return <ErrorDataCard message={t('trends.failedToLoadData')}/>;
    }

    if (flattenedOrganizations) {
        return (
            <>
                <InfiniteLoadTable
                    columns={columns}
                    height={height - 20}
                    data={flattenedOrganizations}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                />
                <ConfirmationDialog open={organizationDeleting}
                                    title={t('feature.organization.delete.dialog.title')}
                                    onClose={onCloseDelete}
                                    onConfirm={onConfirmDelete}
                                    loading={isDeleting}
                                    context={<OrganizationDeleteForm isDeleteError={isDeleteError} formRef={formRef}
                                                         onSubmit={onFormSubmit}/>}/>

                <ConfirmationDialog open={organizationRestoring}
                                    title={t('feature.organization.restore.dialog.title')}
                                    onClose={onCloseRestore}
                                    onConfirm={onConfirmRestore}
                                    loading={isRestoring}
                                    context={<DeleteForm isDeleteError={isRestoreError} formRef={restoreFormRef}
                                                         onSubmit={onRestoreFormSubmit}/>}/>
            </>
        );
    }
}

export default OrganizationsList;