import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useUserChangeState = () => {
  const userChangeState = async (req) => {

    if (req.userState === 'ACTIVE') {
      try {
        const query = new URLSearchParams({});
        query.append('state', 'INACTIVE');
        query.append('organization_id', req.orgId);


        const trials = await api.post(
          `api/v1/users/${req.userId}?${query.toString()}`,
          JSON.stringify(req),
        );

        const data = await trials.json();

        return data.data;
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      try {
        const query = new URLSearchParams({});
        query.append('state', 'ACTIVE');
        query.append('organization_id', req.orgId);

        const trials = await api.post(
          `api/v1/users/${req.userId}?${query.toString()}`,
          JSON.stringify(req),
        );

        const data = await trials.json();

        return data.data;
      } catch (e) {
        return Promise.reject(e);
      }
    }


  };

  return useMutation(({ req }) => userChangeState(req), {});
};

export default useUserChangeState;