import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/elements/PageContainer';
import { ROUTE_USERS } from 'pages/constants';
import Divider from 'components/elements/Divider';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';

import UserForm from './user-form';
import useUsersAdd from '../../../../hooks/users/use-users-add';

const AddUser = () => {
  const { t } = useTranslation();

  return (
    <PageContainer pageTitle={t('feature.organizations.add.pageTitle')}>
      <AddUserPage />
    </PageContainer>
  );
};

const AddUserPage = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const navigate = useNavigate();


  const { mutateAsync, status } = useUsersAdd();
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState('');

  const navigateToUsers = () => {
    navigate(ROUTE_USERS);
  };

  const onFormSubmit = (req) => {
    mutateAsync({ req }).then(() => {
      navigateToUsers();
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleUpdate = () => {
    formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      setMessage(` ${t(`feature.users.add.alert_body`)} .`);
      setShowPopup(true);
    }

  };


  return (
    <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>

      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <Grid
            item
            container
            alignItems='center'
            width='unset'
            sx={{ cursor: 'pointer' }}
            onClick={navigateToUsers}
          >
            <Grid item>
              <Typography>Users</Typography>
            </Grid>
            <Grid item sx={{ pl: '0.3em', '&.MuiGrid-root': { display: 'flex' } }}>
              <OpenInNewIcon />
            </Grid>
          </Grid>
        </Grid>


        <Grid item container xs={6} justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Tooltip title={t('feature.users.button.save.tooltip')}>
              <Button startIcon={<SaveOutlinedIcon />}
                      variant={'contained'}
                      onClick={() => handleUpdate()}
              >
                {status === 'loading' ? (
                    <CircularProgress
                      size={24}
                    />
                  ) :
                  t('feature.users.button.save')}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container justifyContent={'center'} spacing={2}>
        <Grid item container md={12} lg={9}>
          <UserForm formRef={formRef} onUpload={onFormSubmit} />
        </Grid>
      </Grid>

      <Dialog open={showPopup} onClose={handleClosePopup}>
        <DialogTitle>User State Changed</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopup}
            variant='contained'
            color='success'
          >OK</Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default AddUser;