import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import useUserUpdateRoleMappings from 'hooks/users/use-user-update-role-mappings';
import useUserRoleMappings from 'hooks/users/use-user-role-mappings';
import LoaderSpinner from 'components/elements/LoaderSpinner';

import ManageRolesForm from './manage-roles-form';

const ManageRolesDialog = ({open, onClose}) => {
    const {t} = useTranslation();
    const formRef = useRef();

    const [roleMappings, setRoleMappings] = useState([]);
    const [selectedMappings, setSelectedMappings] = useState([]);
    const [isReady, setIsReady] = useState(false)

    const {data, status} = useUserRoleMappings({userId: open});
    const {mutateAsync,status:updateStatus} = useUserUpdateRoleMappings();

    const systemRoles = ['uma_authorization', 'offline_access']

    useEffect(() => {
        if (data && data.data) {
            setIsReady(false)
            const roles = [];
            const selected = []
            data.data['available_role_mappings'].forEach(r => {
                if (!systemRoles.includes(r.name) && !r.name.includes('default-roles')) {
                    roles.push(r)
                }
            })

            data.data['role_mappings'].forEach(r => {
                if (!systemRoles.includes(r.name) && !r.name.includes('default-roles')) {
                    roles.push(r)
                    selected.push(r.id)
                }
            })
            setRoleMappings(roles)
            setSelectedMappings(selected)
            setIsReady(true)
        }
    }, [data])
    const onSave = () => {
        formRef.current.handleSubmit();
    }

    const onSubmit = (values) => {
        const assign = [];
        const unassign = [];
        roleMappings.forEach(r => {
            if (selectedMappings.includes(r.id) && !values.roles.includes(r.id)) {
                unassign.push(r)
            }
            if (!selectedMappings.includes(r.id) && values.roles.includes(r.id)) {
                assign.push(r)
            }
        })
        const req = {
            assign,
            unassign
        };
        mutateAsync({userId: open, req}).finally(() => {
            onClose()
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="draggable-dialog-title"
            fullWidth
        >
            <DialogTitle id="draggable-dialog-title">
                {t('feature.users.manageRoles.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {(status === 'loading' || !isReady) ? <Grid container justifyContent="center" alignItems="center"
                                                                sx={{width: '100%', minHeight: 400}}>
                            <Grid item>
                                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70}
                                               width={70}/>
                            </Grid>
                        </Grid>
                        :
                        <ManageRolesForm onSubmit={onSubmit} formRef={formRef} roleMappings={roleMappings}
                                         selected={selectedMappings}/>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={updateStatus === 'loading'}>
                    Cancel
                </Button>
                <Button onClick={() => onSave(open)} disabled={status === 'loading'|| updateStatus === 'loading'}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ManageRolesDialog;