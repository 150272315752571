import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';

const UserForm = ({ formRef, onUpload, data = {} }) => {
  const { t } = useTranslation();

  const userRoles = [
    {
      id: 'USER',
      name: t('feature.users.roles.user'),
    },
    {
      id: 'GUEST',
      name: t('feature.users.roles.guest'),
    },
  ];

  const initialValues = {
    first_name: data.first_name ?? '',
    last_name: data.last_name ?? '',
    other_name: data.other_name ?? '',
    name_initials: data.name_initials ?? '',
    email: data.email ?? '',
    contact_number: data.contact_number ?? '',
    role: data.role ?? '',
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('feature.users.upload.first_name.required')),
    email: Yup.string().required(t('feature.users.upload.email.required')),
  });


  return (
    <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={onUpload}>
          {({
              handleChange,
              values,
              touched,
              errors,
            }) => (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.first_name')}</FormLabel>
                <TextField id='user-first-name'
                           required
                           name='first_name'
                           value={values.first_name}
                           onChange={handleChange}
                           error={Boolean(touched.first_name && errors.first_name)}
                           helperText={touched.first_name && errors.first_name}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.last_name')}</FormLabel>
                <TextField id='user-last-name'
                           required
                           name='last_name'
                           value={values.last_name}
                           onChange={handleChange}
                           error={Boolean(touched.last_name && errors.last_name)}
                           helperText={touched.last_name && errors.last_name}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>


              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.other_name')}</FormLabel>
                <TextField id='user-other-name'
                           required
                           name='other_name'
                           value={values.other_name}
                           onChange={handleChange}
                           error={Boolean(touched.other_name && errors.other_name)}
                           helperText={touched.other_name && errors.other_name}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.email')}</FormLabel>
                <TextField id='user-email'
                           required
                           disabled
                           name='email'
                           type='email'
                           value={values.email}
                           onChange={handleChange}
                           error={Boolean(touched.email && errors.email)}
                           helperText={touched.email && errors.email}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.name_initials')}</FormLabel>
                <TextField id='user-other-names'
                           required
                           name='name_initials'
                           value={values.name_initials}
                           onChange={handleChange}
                           error={Boolean(touched.name_initials && errors.name_initials)}
                           helperText={touched.name_initials && errors.name_initials}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>


              <Grid item xs={12} sm={6}>
                <FormLabel>{t('feature.users.upload.contact_number')}</FormLabel>
                <TextField id='user-contact-number'
                           required
                           name='contact_number'
                           value={values.contact_number}
                           onChange={handleChange}
                           error={Boolean(touched.contact_number && errors.contact_number)}
                           helperText={touched.contact_number && errors.contact_number}
                           fullWidth
                           variant='outlined' size='small' />
              </Grid>

              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <FormControl error={Boolean(touched.role && errors.role)} disabled>*/}
              {/*    <FormLabel required*/}
              {/*               id='user-role-label'>{t('feature.users.upload.role')}</FormLabel>*/}
              {/*    <RadioGroup*/}
              {/*      row*/}
              {/*      aria-labelledby='user-role-label'*/}
              {/*      name='role'*/}
              {/*      value={values.role}*/}
              {/*      onChange={handleChange}*/}
              {/*    >*/}
              {/*      <>*/}
              {/*        {userRoles.map(userType => (*/}
              {/*          <FormControlLabel value={userType.id} control={<Radio />}*/}
              {/*                            label={userType.name} />*/}
              {/*        ))}*/}
              {/*      </>*/}
              {/*    </RadioGroup>*/}
              {/*    <FormHelperText>{touched.role && errors.role}</FormHelperText>*/}
              {/*  </FormControl>*/}
              {/*</Grid>*/}
            </Grid>)}
        </Formik>
      </Grid>


    </Grid>
  );
};

export default UserForm;