import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import Divider from 'components/elements/Divider';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/elements/PageContainer';
import { ROUTE_ORGANIZATION_ADD } from 'pages/constants';

import OrganizationsList from './organizations-list';
import Filters from './filters';

const Organizations = () => {
  const { t } = useTranslation();

  return (
    <PageContainer pageTitle={t('feature.organizations.pageTitle')}>
      <OrganizationsPage />
    </PageContainer>
  );
};

const OrganizationsPage = ({ containerHeight }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [filters, setFilters] = useState({
    search: '',
  });

  const handleSearch = (search) => {
    setFilters({
      ...filters,
      search,
    });
  };

  const handleClickOpen = () => {
    navigate(ROUTE_ORGANIZATION_ADD);
  };


  return (
    <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
      <Grid item container>
        <Grid item xs={9}>
          <Filters filters={filters} handleSearch={handleSearch} />
        </Grid>
        <Grid item container xs={3} justifyContent='flex-end' alignItems='center'>
          <Grid item>
            <Tooltip title={t('feature.organizations.add')}>
              <IconButton sx={{ display: 'flex', alignItems: 'center' }} color='primary' onClick={handleClickOpen}>
                <AddBusinessIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <OrganizationsList height={containerHeight - 100} filters={filters} />
      </Grid>
    </Grid>
  );
};

export default Organizations;
