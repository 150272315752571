import React from 'react';
import MuiChip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import styled from '@mui/system/styled';

const Chip = styled(MuiChip)(() => ({
    color: 'black',
    height: '1.8em',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '14px',
}));

const LabeledChip = ({label, backgroundColor = '#00d2d3',color='#49494a', infoIcon, title, disableHoverListenerAction = false}) => {
    return title ? (
        <Tooltip
            title={title}
            placement="top"
            disableHoverListener={disableHoverListenerAction}
            arrow
        >
            <Chip
                label={label}
                style={{
                    backgroundColor: backgroundColor,
                    color:color,
                    fontWeight:600,
                    fontSize:'0.8rem'
                }}
                icon={<>{infoIcon === true ? <InfoOutlinedIcon fontSize="small" style={{color: 'white'}}/> : null}</>}
            />
        </Tooltip>
    ) : (
        <Chip
            label={label}
            style={{
                backgroundColor: backgroundColor,
            }}
            icon={<>{infoIcon === true ? <InfoOutlinedIcon fontSize="small" style={{color: 'white'}}/> : null}</>}
        />
    );
};

export default LabeledChip;
