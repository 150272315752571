import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useOrganizationChangeState = () => {
  const organizationChangeState = async (req) => {

    if (req.organizationState === 'ACTIVE') {
      try {
        const query = new URLSearchParams({});
        query.append('state', 'INACTIVE');

        const trials = await api.post(
          `api/v1/organizations/${req.organizationId}?${query.toString()}`,
          JSON.stringify(req),
        );

        const data = await trials.json();

        return data.data;
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      try {
        const query = new URLSearchParams({});
        query.append('state', 'ACTIVE');

        const trials = await api.post(
          `api/v1/organizations/${req.organizationId}?${query.toString()}`,
          JSON.stringify(req),
        );

        const data = await trials.json();

        return data.data;
      } catch (e) {
        return Promise.reject(e);
      }
    }


  };

  return useMutation(({ req }) => organizationChangeState(req), {});
};

export default useOrganizationChangeState;