import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useUser = ({ userId }) => {
  const fetchUser = async (userId) => {
    try {
      const result = await api.get(`api/v1/users/${userId}`);
      const {
        data: {
          payload: { user },
        },
      } = await result.json();

      return { data: user };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['fetch-user-v1', userId], () => fetchUser(userId), {
    refetchOnWindowFocus: false,
    retry:false,
    cacheTime:0
  });
};

export default useUser;
