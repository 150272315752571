import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useOrganizationAdd = () => {
  const organizationAdd = async (req) => {
    try {
      const trials = await api.post(
        `api/v1/organizations`,
        JSON.stringify(req)
      );

      const data = await trials.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ req }) => organizationAdd(req), {});
};

export default useOrganizationAdd;