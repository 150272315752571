import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useUserResetPassword = () => {
    const resetPassword = async (userId, req) => {

    try {

      const response = await api.post(
        `api/v1/users/${userId}/reset-password`,
        JSON.stringify(req),
      );

      const data = await response.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ userId, req }) => resetPassword(userId, req), {});

};

export default useUserResetPassword;