import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
  // organizations
  ROUTE_ORGANIZATIONS,
  ROUTE_ORGANIZATION_VIEW,
  ROUTE_ORGANIZATION_ADD,
  // users
  ROUTE_USERS,
  ROUTE_USER_ADD,
  ROUTE_USER_VIEW,

} from 'pages/constants';


import DashboardLayout from 'layouts/dashboard';
import Organizations from 'pages/app/organizations';
import AddOrganization from 'pages/app/organizations/add-organization';
import ViewOrganization from 'pages/app/organizations/view-organization';

import Users from 'pages/app/users';
import AddUser from './users/add-user';
import ViewUser from './users/view-user';

const AppRouter = () => {
  return (
    <Routes>
      <Route path={ROUTE_ORGANIZATIONS} element={<DashboardLayout />}>
        <Route index path={ROUTE_ORGANIZATIONS} element={<Organizations />} />
        <Route index path={ROUTE_ORGANIZATION_ADD} element={<AddOrganization />} />
        <Route index path={ROUTE_ORGANIZATION_VIEW} element={<ViewOrganization />} />
      </Route>

      <Route path={ROUTE_USERS} element={<DashboardLayout />}>
        <Route index path={ROUTE_USERS} element={<Users />} />
        <Route index path={ROUTE_USER_ADD} element={<AddUser />} />
        <Route index path={ROUTE_USER_VIEW} element={<ViewUser />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;