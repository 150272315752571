import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {format, parseISO} from 'date-fns';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {IconButton} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {useNavigate} from 'react-router-dom';

import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import InfiniteLoadTable from 'components/elements/InfiniteLoadTable';
import ErrorDataCard from 'components/elements/ErrorCard';
import useUsers from 'hooks/users/use-users';
import {ROUTE_USER_VIEW} from 'pages/constants';
import useUserChangeState from 'hooks/users/use-user-change-state';
import useUserDelete from 'hooks/users/use-user-delete';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import DeleteForm from 'components/elements/DeleteForm';

const UsersList = ({
                       height,
                       filters = {
                           search: '',
                       },
                   }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();

    const [flattenedUsers, setFlattenedUsers] = useState([]);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch} = useUsers({
        filters,
    });

    const [userDeleting, setUserDeleting] = useState(null);

    const {mutateAsync: stateChangeAsync} = useUserChangeState();
    const {mutateAsync: deleteAsync,isError: isDeleteError,isLoading: isDeleting} = useUserDelete();

    const handleUpdate = (userId, orgId, userName, userState) => {

        const req = {
            userId,
            orgId,
            userState,
        };

        stateChangeAsync({req}).finally(() => {
            refetch()
        });
    };

    const handleDelete = (user) => {
        setUserDeleting(user);
    };

    const onFormSubmit = (values) => {
        const req = {
            ...values,
            userId: userDeleting.user_id,
            orgId: userDeleting.organization_id,
        }
        deleteAsync({req}).finally(() => {
            setUserDeleting(null);
            refetch().finally();
        });
    };

    const onCloseDelete = () => {
        setUserDeleting(null);
    }

    const onConfirmDelete = () => {
        formRef.current.handleSubmit();
    }

    const handleNameClick = (userId) => {
        navigate(ROUTE_USER_VIEW.replace(':userId', userId));
    };

    useEffect(() => {
        if (data) {
            setFlattenedUsers(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []),
            );
        }
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                align: 'left',
                width: '20%',
                Cell: ({row: {values, original}}) => {
                    return (
                        <Grid item container alignItems='center' sx={{cursor: 'pointer'}}
                              onClick={() => handleNameClick(original.user_id)}>
                            <Grid item>  {original.first_name} {original.last_name}</Grid>
                            <Grid item
                                  sx={{pl: '0.3em', '&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                                <OpenInNewIcon/>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
                align: 'left',
                width: '10%',

                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{values.email}</>;
                },
            },
            {
                Header: 'Status',
                accessor: 'state',
                align: 'center',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{values.state}</>;
                },
            },
            {
                Header: 'Onboard Date',
                accessor: 'onboard_timestamp',
                align: 'center',
                width: '15%',
                Cell: ({
                           cell: {
                               row: {values},
                           },
                       }) => {
                    return <>{format(parseISO(values.onboard_timestamp), 'P')}</>;
                },
            },
            {
                accessor: 'activate',
                align: 'left',
                width: '10%',
                Cell: ({
                           cell: {
                               row: {original, values},
                           },
                       }) => {
                    return (
                        <Grid container justifyContent='center' spacing={2} pr={2}>
                            <Grid item xs={'auto'}
                                  sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center', width: '100%'}}}>

                                {values.state === 'INACTIVE' && (
                                    <Button
                                        fullWidth
                                        size={'small'}
                                        onClick={() => handleUpdate(original.user_id, original.organization_id, original.first_name, original.state)}
                                        variant='contained'
                                        color='success'
                                    >
                                        Activate
                                    </Button>
                                )}
                                {values.state === 'ACTIVE' && (
                                    <Button
                                        fullWidth
                                        size={'small'}
                                        variant='contained'
                                        onClick={() => handleUpdate(original.user_id, original.organization_id, original.first_name, original.state)}
                                        color='error'
                                    >
                                        Deactivate
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                    );
                },
            },
            {
                accessor: 'delete',
                align: 'right',
                width: '3%',
                Cell: ({
                           cell: {
                               row: {original, values},
                           },
                       }) => {
                    return (
                        <Grid xs={2} item sx={{'&.MuiGrid-root': {display: 'flex', alignItems: 'center'}}}>
                            <Tooltip title={t('feature.users.user.delete.icon')}>
                                <IconButton
                                    sx={{display: 'flex', alignItems: 'center'}}
                                    color='secondary'
                                    onClick={() => handleDelete(original)}
                                >
                                    <DeleteOutlineOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    );
                },
            },
        ],
        [t],
    );

    if (status === 'loading') {
        return <>Loading...</>;
    }

    if (isError) {
        return <ErrorDataCard message={t('trends.failedToLoadData')}/>;
    }

    if (flattenedUsers) {
        return (
            <>
                <InfiniteLoadTable
                    columns={columns}
                    height={height - 20}
                    data={flattenedUsers}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                />
                <ConfirmationDialog open={userDeleting}
                                    title={t('feature.users.delete.dialog.title')}
                                    onClose={onCloseDelete}
                                    onConfirm={onConfirmDelete}
                                    loading={isDeleting}
                                    context={<DeleteForm isDeleteError={isDeleteError} formRef={formRef}
                                                         onSubmit={onFormSubmit}/>}/>
            </>
        );
    }
};

export default UsersList;