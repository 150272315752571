import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useUserUpdate = () => {
    const updateUser = async (userId, orgId, req) => {
    const query = new URLSearchParams({});
    query.append('organization_id', orgId);

    try {

      const response = await api.put(
        `api/v1/users/${userId}?${query.toString()}`,

        JSON.stringify(req),
      );

      const data = await response.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ userId, orgId, req }) => updateUser(userId, orgId, req), {});

};

export default useUserUpdate;