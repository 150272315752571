import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';

const Filters = ({ filters, handleSearch = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Grid container flexwrap="noWrap" justifyContent="space-between" gap={1}>
      <Grid item xs={4}>
        <DebouncedSearchField onSearch={handleSearch} placeholder={t('evaluate.results.filters.search.placeholder')} />
      </Grid>
    </Grid>
  );
};

export default Filters;
