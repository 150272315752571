import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import PrivateRoute from 'components/base/PrivateRoute';
import AppInitializer from "components/base/AppInitializer";

import {ROUTE_ORGANIZATIONS} from './constants';


const AppRoutes = () => {
    const {t} = useTranslation();

    return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Helmet title={t('app.name')}/>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <PrivateRoute>
                                <AppInitializer/>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/" element={<Navigate to={ROUTE_ORGANIZATIONS} replace/>}/>
                </Routes>
            </BrowserRouter>
    );
};

export default AppRoutes;
