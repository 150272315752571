import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  activePageTitle: '',
  activePageDescription: '',
  drawerOpen: false,
  isPDP: false,
  dataset: '',
  selectedDatasetId: '',
  initData: null,
  organization_id: null,
  organizations: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    notifyChangePageTitle(state, action) {
      state.activePageTitle = action.payload;
    },
    notifyDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
    notifyDatasetChange(state, action) {
      state.dataset = action.payload;
    },
    notifyDatasetSelect(state, action) {
      state.selectedDatasetId = action.payload;
    },
    notifyInitData(state, action) {
      state.initData = action.payload;
    },
    notifyIsPDP(state, action) {
      state.isPDP = action.payload;
    },
    setUniqueOrganizations(state, action) {
      console.log(action.payload);
      state.organizations = action.payload;
    },
    setOrganizationId(state, action) {
      state.organization_id = action.payload;
    },
  },
});

export const { name, actions, reducer } = appSlice;
export const {
  notifyChangePageTitle,
  notifyDrawerOpen,
  notifyDatasetChange,
  notifyDatasetSelect,
  notifyInitData,
  notifyIsPDP,
  setUniqueOrganizations,
  setOrganizationId,
} = actions;
export default reducer;
