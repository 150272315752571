import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useOrganization = ({ orgId }) => {
  const fetchOrganization = async (orgId) => {
    try {
      const result = await api.get(`api/v1/organizations/${orgId}`);
      const {
        data: {
          payload: {organization},
        },
      } = await result.json();

      return {data:organization};
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['fetch-org-v1', orgId], () => fetchOrganization(orgId), {
    refetchOnWindowFocus: true,
    retry : false
  });
};

export default useOrganization;
