import React from 'react';

import Grid from '@mui/material/Grid';
import {Alert, AlertTitle} from "@mui/lab";

const CustomError = ({message}) => {
    return (
        <Grid container justifyContent="center" alignContent={"center"} sx={{width: '100%', height: '100vh'}}>
            <Alert severity="error" sx={{width: '1000px'}}>
                <AlertTitle>ERROR</AlertTitle>
                {message}
            </Alert>
        </Grid>
    );
};

export default CustomError;
