import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useUsers = ({filters}) => {

    const fetchUsers = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam,
        });

        if (filters.search) {
            query.append('name', filters.search);
        }

        if (filters.organization_id) {
            query.append('organization_id', filters.organization_id);
        }

        try {
            const results = await api.get(`api/v1/users?${query.toString()}`);
            const {
                data: {
                    payload: {users},
                    meta,
                },
            } = await results.json();

            return {data: users, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['users', filters], fetchUsers, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useUsers;
