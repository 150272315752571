import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useOrganizationUpdate = () => {
  const updateOrganization = async (orgId,req) => {
    try {
      const response = await api.put(
        `api/v1/organizations/${orgId}`,
        JSON.stringify(req)
      );

      const data = await response.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ req,orgId }) => updateOrganization(orgId,req), {});
};

export default useOrganizationUpdate;