import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { useViewport } from 'hooks/useViewport';
import { notifyChangePageTitle } from 'state/app';

const PageContainer = ({ pageTitle, pageDescription, pageTooltip, children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { height } = useViewport();

  useLayoutEffect(() => {
    dispatch(notifyChangePageTitle({ pageTitle, pageDescription, pageTooltip }));
  }, [dispatch, location, pageTitle, pageDescription, pageTooltip]);

  const containerHeight = height - (theme.mixins.toolbar.minHeight + 1) - parseInt(theme.layouts.dashboard.padding, 10);

  if (!children) {
    return <></>;
  }

  return <>{React.cloneElement(children, { containerHeight })}</>;
};

export default PageContainer;
