import { createContext, useState, useEffect, useContext } from 'react';

const viewportContext = createContext({});

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const ViewportProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    const debouncedHandleResize = debounce(handleResize, 500);

    window.addEventListener('resize', debouncedHandleResize);

    // call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return <viewportContext.Provider value={windowSize}>{children}</viewportContext.Provider>;
};

const useViewport = () => {
  const windowSize = useContext(viewportContext);
  return windowSize;
};

export { ViewportProvider, useViewport };
