import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Keycloak from 'keycloak';
import {notifyIsPDP} from 'state/app';
import {notifyAuthSuccess} from 'state/auth';

const authContext = createContext({initialized: false, isError: false, authClient: null});

const AuthContextProvider = ({children, authClient}) => {
    const dispatch = useDispatch();

    const [initialized, setInitialized] = useState(false);
    const [isError, setIsError] = useState(false);

    Keycloak.onAuthSuccess = () => {
        localStorage.setItem('CML_INSIGHTS_USER_ACCESS_TOKEN', Keycloak.token)
        localStorage.setItem('CML_INSIGHTS_USER_ID', Keycloak.tokenParsed.sub)
        dispatch(notifyIsPDP(Keycloak.tokenParsed.is_pdp));
        Keycloak.loadUserInfo().then(profile => {
            dispatch(notifyAuthSuccess({authenticated: Keycloak.authenticated, profile, roles: Keycloak.realmAccess}))
        })

    }

    Keycloak.onTokenExpired = useCallback(async () => {
        const tokenUpdated = await Keycloak.updateToken(120);
        if (tokenUpdated) {
            localStorage.setItem('CML_INSIGHTS_USER_ACCESS_TOKEN', Keycloak.token);
        } else {
            console.log('Error refreshing the token');
        }
    }, []);

    Keycloak.onAuthLogout = () => {
        localStorage.removeItem('CML_INSIGHTS_USER_ACCESS_TOKEN')
        localStorage.removeItem('CML_INSIGHTS_USER_ID')
    }

    useEffect(() => {
        const initOptions = {
            onLoad: 'login-required',
            checkLoginIframe: false,
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
        }

        authClient.init(initOptions).then(() => {
            setInitialized(true)
        }).catch(function () {
            setIsError(true)
        });
    }, [])

    return <authContext.Provider value={{initialized, isError, authClient}}>{children}</authContext.Provider>;
};

const useAuth = () => {
    const ctx = useContext(authContext)

    if (!ctx) {
        throw new Error(
            'useKeycloak hook must be used inside ReactKeycloakProvider context'
        )
    }

    if (!ctx.authClient) {
        throw new Error('authClient has not been assigned to ReactKeycloakProvider')
    }

    const {authClient, initialized} = ctx
    return {
        initialized,
        keycloak: authClient,
    }
};

export {AuthContextProvider, useAuth};
