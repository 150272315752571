import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import PageContainer from 'components/elements/PageContainer';
import { ROUTE_ORGANIZATIONS, ROUTE_USER_ADD } from 'pages/constants';
import Divider from 'components/elements/Divider';
import useOrganization from 'hooks/organizations/use-organization';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import { useViewport } from 'hooks/useViewport';
import useOrganizationUpdate from 'hooks/organizations/use-organization-update';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import { setOrganizationId } from 'state/app';

import OrganizationForm from './organization-form';
import { useDispatch } from 'react-redux';

const ViewOrganization = () => {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const { height } = useViewport();

  const { data, isLoading } = useOrganization({ orgId });

  if (isLoading) {
    return <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', height: height - 160 }}>
      <Grid item>
        <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70} />
      </Grid>
    </Grid>;
  }

  if (!data) {
    return <>No data</>;
  }

  return (
    <PageContainer pageTitle={t(data.data.name)}>
      <ViewOrganizationPage data={data.data} />
    </PageContainer>
  );
};

const ViewOrganizationPage = ({ data }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { mutateAsync, status } = useOrganizationUpdate();
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState('');

  const navigateToOrganizations = () => {
    navigate(ROUTE_ORGANIZATIONS);
  };

  const onFormSubmit = (values) => {
    const req = {
      name: values.name,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      country: values.country,
      zip: values.zip,
      website: values.website,
      contact_person: values.contact_person,
      contact_details: values.contact_details,
      comments: values.comments,
    };
    mutateAsync({ orgId: data.organization_id, req }).then(() => {
      navigateToOrganizations();
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleUpdate = () => {
    formRef.current.handleSubmit();
  };

  const handleAddUser = () => {
    dispatch(setOrganizationId(data.organization_id));
    navigate(ROUTE_USER_ADD);
  };


  return (
    <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>

      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <Grid
            item
            container
            alignItems='center'
            width='unset'
            sx={{ cursor: 'pointer' }}
            onClick={navigateToOrganizations}
          >
            <Grid item>
              <Typography>Organizations</Typography>
            </Grid>
            <Grid item sx={{ pl: '0.3em', '&.MuiGrid-root': { display: 'flex' } }}>
              <OpenInNewIcon />
            </Grid>
          </Grid>
        </Grid>


        <Grid item container xs={6} justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Tooltip title={t('feature.organizations.button.add.newuser')}>
              <Button startIcon={<PersonAddAltIcon />}
                      variant={'contained'}
                      disabled={status === 'loading'}
                      onClick={() => handleAddUser()}
              >
                {t('feature.organizations.button.add.newuser')}
              </Button>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={t('feature.organizations.button.save.tooltip')}>
              <Button startIcon={<SaveOutlinedIcon />}
                      variant={'contained'}
                      onClick={() => handleUpdate()}
              >
                {status === 'loading' ? (
                    <CircularProgress
                      size={24}
                    />
                  ) :
                  t('feature.organizations.button.save')}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container justifyContent={'center'} spacing={2}>
        <Grid item container md={12} lg={9}>
          <OrganizationForm formRef={formRef} onUpload={onFormSubmit} data={data} />
        </Grid>
      </Grid>

      <Dialog open={showPopup} onClose={handleClosePopup}>
        <DialogTitle>Organization State Changed</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopup}
            variant='contained'
            color='success'
          >OK</Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default ViewOrganization;