import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useOrganizationDelete = () => {
    const organizationDelete = async (req) => {

        try {
            const query = new URLSearchParams({});

            query.append('support_ticket_id', req.support_ticket_id);

            if (req.requested_by) {
                query.append('requested_by', req.requested_by);
            }
            if (req.reason) {
                query.append('reason', req.reason);
            }

            if (req.grace_period) {
                query.append('grace_period', req.grace_period);
            }

            const trials = await api.delete(
                `api/v1/organizations/${req.organizationId}?${query.toString()}`,
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({req}) => organizationDelete(req), {});
};

export default useOrganizationDelete;