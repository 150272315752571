import {Trans} from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

// base routes
export const ROUTE_ROOT = '/';

// organization routes
export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_ORGANIZATION_ADD = `${ROUTE_ORGANIZATIONS}/add`;
export const ROUTE_ORGANIZATION_VIEW = `${ROUTE_ORGANIZATIONS}/:orgId`;
// user routes
export const ROUTE_USERS = '/users';
export const ROUTE_USER_ADD = `${ROUTE_USERS}/add`;

export const ROUTE_USER_VIEW = `${ROUTE_USERS}/:userId`;

export const pathToIndex = {
    [ROUTE_ORGANIZATIONS]: 0,
    [ROUTE_ORGANIZATION_ADD]: 1,
    [ROUTE_ORGANIZATION_VIEW]: 2,
    [ROUTE_USERS]: 3,
    [ROUTE_USER_ADD]: 4,
    [ROUTE_USER_VIEW]: 5,
};

export const Routes = [
    {
        id: pathToIndex[ROUTE_ORGANIZATIONS],
        path: ROUTE_ORGANIZATIONS,
        name: <Trans i18nKey='feature.organizations'/>,
        icon: <BusinessIcon/>,

    },
    {
        id: pathToIndex[ROUTE_USERS],
        path: ROUTE_USERS,
        name: <Trans i18nKey='feature.users'/>,
        icon: <PeopleOutlineIcon/>,

    },
];