import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import CustomError from 'components/elements/CustomError';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AppRouter from 'pages/app';
import useInit from 'hooks/init/use-init';
import {notifyInitData} from 'state/app';

const AppInitializer = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isCheck, setIsCheck] = useState(false);
    const isInitialInfoLoaded = true;
    const {data, isError, status} = useInit();

    useEffect(() => {
        setIsCheck(false);
        if (data && data.data) {
            dispatch(notifyInitData(data.data));
            setIsCheck(true);
        }
    }, [data]);


    if (isError) {
        return <CustomError message={t('Failed to initialize.')}/>;
    } else {
        if (!isCheck) {
            return (
                <Grid container justifyContent='center' alignItems='center' sx={{width: '100%', height: '100vh'}}>
                    <Grid item>
                        <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70}/>
                    </Grid>
                </Grid>
            );
        } else {
            if (!isInitialInfoLoaded) {
                return <CustomError message={t('Failed to initialize.')}/>;
            } else {
                return <AppRouter/>;
            }
        }
    }
};

export default AppInitializer;
