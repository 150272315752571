import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useOrganizations = ({filters}) => {

    const fetchOrganizations = async ({pageParam = 1}) => {
        const query = new URLSearchParams({
            page: pageParam
        });

        if (filters.search) {
            query.append('name', filters.search);
        }

        try {
            const results = await api.get(`api/v1/organizations?${query.toString()}`);
            const {
                data: {
                    payload: {organizations},
                    meta,
                },
            } = await results.json();

            return {data: organizations, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['organizations', filters], fetchOrganizations, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useOrganizations;
