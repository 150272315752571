import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import {
    TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';

const DeleteForm = ({ formRef, onSubmit,isDeleteError}) => {
    const { t } = useTranslation();

    const initialValues = {
        support_ticket_id: '',
        requested_by: '',
        reason: ''
    };

    const validationSchema = Yup.object().shape({
        support_ticket_id: Yup.string().required(t('component.delete_form.support_ticket_id.required'))
    });


    return (
        <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
            {isDeleteError && (
                <Grid item container justifyContent="center" xs={12}>
                    <Alert severity="error" sx={{width: '100%'}}>
                        {t('feature.users.delete.error')}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={onSubmit}>
                    {({
                          handleChange,
                          values,
                          touched,
                          errors,
                      }) => (
                        <Grid container spacing={2} my={0} >
                            <Grid item xs={12} sm={6}>
                                <TextField id='user-delete-support-ticket-id'
                                           label={t('component.delete_form.support_ticket_id')}
                                           required
                                           name='support_ticket_id'
                                           value={values.support_ticket_id}
                                           onChange={handleChange}
                                           error={Boolean(touched.support_ticket_id && errors.support_ticket_id)}
                                           helperText={touched.support_ticket_id && errors.support_ticket_id}
                                           fullWidth
                                           variant='outlined' size='small' />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField id='user-delete-requested-by'
                                           label={t('component.delete_form.requested_by')}
                                           name='requested_by'
                                           value={values.requested_by}
                                           onChange={handleChange}
                                           error={Boolean(touched.requested_by && errors.requested_by)}
                                           helperText={touched.requested_by && errors.requested_by}
                                           fullWidth
                                           variant='outlined' size='small' />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField id='user-delete-reason'
                                           label={t('component.delete_form.reason')}
                                           name='reason'
                                           value={values.reason}
                                           onChange={handleChange}
                                           error={Boolean(touched.reason && errors.reason)}
                                           helperText={touched.reason && errors.reason}
                                           fullWidth
                                           multiline
                                           rows={3}
                                           variant='outlined' size='small' />
                            </Grid>
                        </Grid>)}
                </Formik>
            </Grid>

        </Grid>
    );
};

export default DeleteForm;