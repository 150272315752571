import {useQuery} from '@tanstack/react-query';
import api from 'services/api';

const useUserRoleMappings = ({userId}) => {
    const fetchRoleMappings = async (userId) => {

        try {

            const response = await api.get(
                `api/v1/users/${userId}/role-mappings`
            );

            const {
                data: {
                    payload,
                    meta,
                },
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['fetch-role-mappings', userId], () => fetchRoleMappings(userId), {
        refetchOnWindowFocus: true,
        retry: false,
        cacheTime:0
    });

};

export default useUserRoleMappings;