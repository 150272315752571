import React from 'react';

import Grid from '@mui/material/Grid';

// const useStyles = makeStyles({
//   widget: {
//     padding: '1.5em',
//     background: 'rgb(253, 236, 234)',
//     boxShadow: ' 0px 1px 8px rgba(20, 46, 110, 0.1)',
//     borderRadius: '8px',
//     height: '100%',
//     width: '30%',
//   },
//   widgetContent: {
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//     width: '100%',
//   },
//   widgetHeader: {
//     flex: 0,
//     textAlign: 'center',
//   },
//   widgetTitle: {
//     fontSize: '1rem',
//     fontWeight: 'bold',
//     marginBottom: '1rem',
//     '@media (min-width: 1960px)': {
//       fontSize: '1.8rem',
//     },
//   },
// });

const ErrorCard = ({ message }) => {
  return <Grid container justify="center" alignItems="center" style={{ marginTop: '5%' }}></Grid>;
};

export default ErrorCard;
