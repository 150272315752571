import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import {useSelector} from 'react-redux';
import {useEffect, useState} from "react";

const OrganizationForm = ({formRef, onUpload, data = {}}) => {
    const {t} = useTranslation();

    const [organizationTypes, setOrganizationTypes] = useState([]);

    const initData = useSelector((state) => state.app.initData);

    useEffect(() => {
        if (initData) {
            let orgTypes = []
            for (const orgType of initData.organization_types) {
                orgTypes.push({
                    id: orgType.type_id,
                    name: t(orgType.name),
                });
            }
            setOrganizationTypes(orgTypes)
        }
    }, [initData])


    const initialValues = {
        name: data.name ?? '',
        type: data.type ?? '',
        access_level: data.access_level ?? '',
        address_line_1: data.address_line_1 ?? '',
        address_line_2: data.address_line_2 ?? '',
        city: data.city ?? '',
        country: data.country ?? '',
        zip: data.zip ?? '',
        website: data.website ?? '',
        contact_person: data.contact_person ?? '',
        contact_details: data.contact_details ?? '',
        comments: data.comments ?? '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('feature.organizations.form.name.required')),
    });

    const accessLevels = [
        {
            id: "FULL",
            name: "Full"
        },
        {
            id: "RESTRICTED",
            name: "Restricted"
        }
    ]


    return (
        <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={onUpload}>
                    {({
                          handleChange,
                          values,
                          touched,
                          errors,
                      }) => (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormLabel>{t('feature.organizations.form.name')}</FormLabel>
                                <TextField id='organization-name'
                                           required
                                           name='name'
                                           value={values.name}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={touched.name && errors.name}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl error={Boolean(touched.type && errors.type)} disabled>
                                    <FormLabel required
                                               id='organization-type-label'>{t('feature.organizations.form.type')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='organization-type-label'
                                        name='type'
                                        value={values.type}
                                        onChange={handleChange}
                                    >
                                        <>
                                            {organizationTypes.map(organizationType => (
                                                <FormControlLabel value={organizationType.id} control={<Radio/>}
                                                                  label={organizationType.name}/>
                                            ))}
                                        </>
                                    </RadioGroup>
                                    <FormHelperText>{touched.type && errors.type}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl error={Boolean(touched.access_level && errors.access_level)}>
                                    <FormLabel required
                                               id='access_level-label'>{t('Access Level')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='access_level-label'
                                        name='access_level'
                                        value={values.access_level}
                                        onChange={handleChange}
                                    >
                                        <>
                                            {accessLevels.map(accessLevel => (
                                                <FormControlLabel value={accessLevel.id} control={<Radio/>}
                                                                  disabled
                                                                  label={accessLevel.name}/>
                                            ))}
                                        </>
                                    </RadioGroup>
                                    <FormHelperText>{touched.access_level && errors.access_level}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.website')}</FormLabel>
                                <TextField id='organization-website'
                                           required
                                           name='website'
                                           value={values.website}
                                           onChange={handleChange}
                                           error={Boolean(touched.website && errors.website)}
                                           helperText={touched.website && errors.website}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.address_line_1')}</FormLabel>
                                <TextField id='organization-address_line_1'
                                           required
                                           name='address_line_1'
                                           value={values.address_line_1}
                                           onChange={handleChange}
                                           error={Boolean(touched.address_line_1 && errors.address_line_1)}
                                           helperText={touched.address_line_1 && errors.address_line_1}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.address_line_2')}</FormLabel>
                                <TextField id='organization-address_line_2'
                                           required
                                           name='address_line_2'
                                           value={values.address_line_2}
                                           onChange={handleChange}
                                           error={Boolean(touched.address_line_2 && errors.address_line_2)}
                                           helperText={touched.address_line_2 && errors.address_line_2}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.city')}</FormLabel>
                                <TextField id='organization-city'
                                           required
                                           name='city'
                                           value={values.city}
                                           onChange={handleChange}
                                           error={Boolean(touched.city && errors.city)}
                                           helperText={touched.city && errors.city}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.country')}</FormLabel>
                                <TextField id='organization-country'
                                           required
                                           name='country'
                                           value={values.country}
                                           onChange={handleChange}
                                           error={Boolean(touched.country && errors.country)}
                                           helperText={touched.country && errors.country}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.zip')}</FormLabel>
                                <TextField id='organization-zip'
                                           required
                                           name='zip'
                                           value={values.zip}
                                           onChange={handleChange}
                                           error={Boolean(touched.zip && errors.zip)}
                                           helperText={touched.zip && errors.zip}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.contact_person')}</FormLabel>
                                <TextField id='organization-contact-person'
                                           required
                                           name='contact_person'
                                           value={values.contact_person}
                                           onChange={handleChange}
                                           error={Boolean(touched.contact_person && errors.contact_person)}
                                           helperText={touched.contact_person && errors.contact_person}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel>{t('feature.organizations.form.contact_details')}</FormLabel>
                                <TextField id='organization-contact-details'
                                           required
                                           name='contact_details'
                                           value={values.contact_details}
                                           onChange={handleChange}
                                           error={Boolean(touched.contact_details && errors.contact_details)}
                                           helperText={touched.contact_details && errors.contact_details}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormLabel>{t('feature.organizations.form.comments')}</FormLabel>
                                <TextField id='organization-comments'
                                           required
                                           multiline
                                           rows={5}
                                           name='comments'
                                           value={values.comments}
                                           onChange={handleChange}
                                           error={Boolean(touched.comments && errors.comments)}
                                           helperText={touched.comments && errors.comments}
                                           fullWidth
                                           variant='outlined' size='small'/>
                            </Grid>


                        </Grid>)}
                </Formik>
            </Grid>


        </Grid>
    );
};

export default OrganizationForm;