import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {
    FormControlLabel, Switch,
    TextField,
} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';

const UserSetPasswordForm = ({formRef, onSubmit}) => {
    const {t} = useTranslation();

    const initialValues = {
        password: '',
        confirm_password: '',
        temporary: false
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('feature.users.setPassword.newPassword.required')),
        confirm_password: Yup.string().required(t('feature.users.setPassword.newPasswordConfirmation.required'))
            .oneOf([Yup.ref('password'), null], t('feature.users.setPassword.newPasswordConfirmation.match'))
    });


    return (
        <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={onSubmit}>
                    {({
                          handleChange,
                          values,
                          touched,
                          errors,
                      }) => (
                        <Grid container spacing={2} my={0}>
                            <Grid item xs={12}>
                                <TextField id='user-reset-password'
                                           label={t('feature.users.setPassword.newPassword')}
                                           required
                                           name='password'
                                           value={values.password}
                                           onChange={handleChange}
                                           error={Boolean(touched.password && errors.password)}
                                           helperText={touched.password && errors.password}
                                           fullWidth
                                           type={"password"}
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id='user-reset-password-confirm'
                                           label={t('feature.users.setPassword.newPasswordConfirmation')}
                                           name='confirm_password'
                                           required
                                           value={values.confirm_password}
                                           onChange={handleChange}
                                           error={Boolean(touched.confirm_password && errors.confirm_password)}
                                           helperText={touched.confirm_password && errors.confirm_password}
                                           fullWidth
                                           type={"password"}
                                           variant='outlined' size='small'/>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormControlLabel
                                    sx={{marginLeft: 0}}
                                    control={<Switch color="primary"
                                                     name='temporary'
                                                     onChange={handleChange}
                                                     checked={values.temporary}
                                                     error={Boolean(touched.temporary && errors.temporary)}
                                                     helperText={touched.temporary && errors.temporary}/>}
                                    label={t('feature.users.setPassword.temporary')}
                                    labelPlacement="start"
                                />
                            </Grid>
                        </Grid>)}
                </Formik>
            </Grid>

        </Grid>
    );
};

export default UserSetPasswordForm;