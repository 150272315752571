import React, {useState} from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@mui/system/styled';
import { useDispatch } from 'react-redux';
import { notifyDrawerOpen } from 'state/app';

import Header from 'components/base/Header';
import SideBar from 'components/base/SideBar';

const contentMixin = (theme) => ({
  flexGrow: 1,
  flexDirection: 'column',
  padding: theme.layouts.dashboard.padding,
  overflowY: 'auto', // this prevents scrollbar squashing the contents
  overflowX: 'hidden',
});

const PageSpacing = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const shiftContextMixin = (theme) => ({
    marginLeft: theme.cmlInsights.drawer.openWidth,
    transition: "margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)",
});

const unShiftContent = (theme) => ({
    marginLeft: theme.cmlInsights.drawer.closeWidth,
    transition: "margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)",
});

const PageContainer = styled("main", {
    shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ drawerOpen, theme }) => ({
    ...contentMixin(theme),
    ...(drawerOpen && shiftContextMixin(theme)),
    ...(!drawerOpen && unShiftContent(theme)),
}));

const DashboardLayout = () => {
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        dispatch(notifyDrawerOpen(!drawerOpen));
        setDrawerOpen(!drawerOpen);
    };
  return (
    <>
      <Header drawerOpen={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
      <SideBar drawerOpen={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
      <PageContainer drawerOpen={drawerOpen}>
        <PageSpacing />
        <Outlet />
      </PageContainer>
    </>
  );
};

export default DashboardLayout;
