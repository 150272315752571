import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Grid from "@mui/material/Grid";

import ErrorFallback from 'components/base/ErrorFallback';
import {useAuth} from 'hooks/useAuth';
import LoaderSpinner from 'components/elements/LoaderSpinner';

function Redirect() {
    const {keycloak} = useAuth();

    keycloak.login()

    return null;
}

const PrivateRoute = (props) => {
    const {keycloak, initialized} = useAuth();
    const {path} = props;

    if (!initialized) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: '100vh'}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    const isLoggedIn = !!keycloak.idToken && !!keycloak.token;

    return (isLoggedIn) ? (
        <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[path]}>
            {props.children}
        </ErrorBoundary>
    ) : (
        <>
            <Routes>
                <Route path={"*"} element={<Redirect/>}/>
            </Routes>
        </>
    );
};

export default PrivateRoute;
