import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useUserUpdateRoleMappings = () => {
    const updateRoleMappings = async (userId, req) => {

    try {

      const response = await api.post(
        `api/v1/users/${userId}/role-mappings`,
        JSON.stringify(req),
      );

      const data = await response.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ userId, req }) => updateRoleMappings(userId, req), {});

};

export default useUserUpdateRoleMappings;