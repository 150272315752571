import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

const useUsersAdd = () => {
  const usersAdd = async (req) => {
    console.log(req);
    try {
      const trials = await api.post(
        `api/v1/users`,
        JSON.stringify(req),
        {
          timeout: 10000,
        },
      );

      const data = await trials.json();

      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useMutation(({ req }) => usersAdd(req), {});
};

export default useUsersAdd;