import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';

const Filters = ({
                     filters, handleFilter = () => {
    },
                 }) => {
    const {t} = useTranslation();

    const handleOrganizationChange = (event, newValue, reason) => {

        handleFilter({
            ...filters,
            organization_id: newValue ? newValue.organization_id : '',
        });
    }

    return (
        <Grid container flexwrap='noWrap' justifyContent='space-between' gap={1}>
            <Grid item xs={4}>
                <DebouncedSearchField onSearch={(search) => {
                    handleFilter({
                        ...filters,
                        search,
                    });
                }} placeholder={t('evaluate.results.filters.search.placeholder')}/>
            </Grid>
            <Grid item xs={4}>
                <CustomAutocomplete onChange={handleOrganizationChange} id={'organizations'} label={"Organization"}/>
            </Grid>
        </Grid>
    );
};

export default Filters;
