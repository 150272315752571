import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useOrganizations from "../../../hooks/organizations/use-organizations";

// const CustomAutocomplete = ({
//                                 id,
//                                 value,
//                                 onChange,
//                                 setFieldValue,
//                                 name,
//                                 options = [],
//                                 required = false,
//                                 disableCloseOnSelect = true,
//                                 error = false,
//                                 label = '',
//                                 placeholder = '',
//                                 size = 'small'
//                             }) => {
//     const [inputValue, setInputValue] = useState(null);
//
//     const getValue = (val) => {
//         const i = options.findIndex(o => o.id === val)
//         if (i > -1) {
//             return options[i]
//         } else {
//             return null
//         }
//     }
//
//     useEffect(() => {
//         setInputValue(getValue(value))
//     }, [value])
//
//     return (
//         <Autocomplete
//             size={size}
//             options={options}
//             onChange={(e, value) => setFieldValue(name, value ? value.id : '')}
//             disableCloseOnSelect={disableCloseOnSelect}
//             value={inputValue}
//             getOptionLabel={(option) => (option ? option.name : "")}
//             sx={{width: '100%'}}
//             renderInput={(params) => (
//                 <TextField required={required}
//                            {...params}
//                            onChange={onChange}
//                            value={value}
//                            id={`${id}`}
//                            name={name}
//                            label={label}
//                            error={error}
//                            placeholder={placeholder}/>
//             )}
//         />
//     );
// }
//



// managing state
const CustomAutocomplete = ({ id,onChange,label = '',})=> {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
    });
    const loading = open && options.length === 0;

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status} = useOrganizations({
        filters,
    });

    // Setting the logic for the asynchronous function on page reload
    React.useEffect(() => {
        if (data) {
            setOptions(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []),
            );
        }
    }, [data]);

    return (
        <Autocomplete
            id={`autocomplete-${id}`}
            fullWidth
            size={"small"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => value === undefined || option?.organization_id?.toString() === (value?.organization_id ?? value)?.toString()}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default CustomAutocomplete;