import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {
    FormControlLabel, Switch,
    TextField,
} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import CustomMultiSelect from "../../../../components/elements/CustomMultiSelect";

const ManageRolesForm = ({formRef, onSubmit, roleMappings = [], selected = []}) => {
    const {t} = useTranslation();
    const initialValues = {
        roles: selected
    };

    const validationSchema = Yup.object().shape({
    });


    return (
        <Grid container flexDirection='column' flexWrap='nowrap' spacing={2}>
            <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={onSubmit}>
                    {({
                          handleChange,
                          values,
                          touched,
                          errors,
                          setFieldValue
                      }) => (
                        <Grid container spacing={2} my={0}>
                            <Grid item xs={12}>
                                <CustomMultiSelect
                                    value={values.roles}
                                    onChange={setFieldValue}
                                    label={t("feature.users.manageRoles.roles")}
                                    name="roles"
                                    inputProps={{
                                        id: 'roles',
                                    }}
                                    height={200}
                                    error={Boolean(touched.roles && errors.roles)}
                                    options={roleMappings}
                                />
                            </Grid>
                        </Grid>)}
                </Formik>
            </Grid>

        </Grid>
    );
};

export default ManageRolesForm;