import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import Avatar from 'components/icons/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

import {useAuth} from 'hooks/useAuth';

const UserProfileContainer = styled(Grid)({
    height: '59px',
    width: 'auto',
    padding: '0 0 0 16px',
});

const ListItemIcon = styled(MuiListItemIcon)({
    minWidth: '36px',
});

const PageTitle = styled(Typography)(({theme}) => ({
    color: 'black',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '2.3rem',

    [theme.breakpoints.up('lg')]: {
        fontSize: '2.2rem',
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: '1.2rem',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({drawerOpen, theme}) => ({
    padding: theme.spacing(0, 3, 0, 3),
    width: `calc(100% - ${theme.cmlInsights.drawer.closeWidth})`,
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    ...(drawerOpen && {
        width: `calc(100% - ${theme.cmlInsights.drawer.openWidth})`,
    }),
}));

const AccountMenu = ({anchorEl, open, handleClose, handleLogout}) => {

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 2,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

const Header = ({drawerOpen, handleDrawerOpen}) => {
    const {t} = useTranslation();

    const activePageTitle = useSelector((state) => state.app.activePageTitle);
    const profile = useSelector((state) => state.auth.profile);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {keycloak, initialized} = useAuth();

    const handleLogout = async (e) => {
        e.preventDefault();
        if (initialized) {
            keycloak.logout()
        }
    };

    return (
        <React.Fragment>
            <AppBar position="fixed" drawerOpen={drawerOpen}>
                <Toolbar disableGutters>
                    <Grid container flexWrap="nowrap" justifyContent={"space-between"}>
                        <Grid item container alignItems="center" flexWrap="nowrap" lg={7} md={7}>
                            <Tooltip title={activePageTitle.pageTitle}>
                                <PageTitle data-testid={'page-title'}>{activePageTitle.pageTitle}</PageTitle>
                            </Tooltip>
                            {activePageTitle.pageTooltip && (
                                <Box sx={{paddingLeft: '12px', display: 'flex'}}>
                                    <Tooltip title={activePageTitle.pageTooltip}>
                                        <InfoIcon color={'primary'}/>
                                    </Tooltip>
                                </Box>
                            )}
                        </Grid>

                        <Grid item container spacing={2} justifyContent="flex-end" flexWrap="nowrap" alignItems="center"
                              lg={4}
                              md={5}>

                            <UserProfileContainer item container alignItems="center" flexWrap="nowrap"
                                                  onClick={handleClick}>
                                <Avatar userName={profile ? (profile.name ?? profile.preferred_username) : ''}/>
                            </UserProfileContainer>
                            <AccountMenu handleClose={handleClose} open={open} anchorEl={anchorEl}
                                         handleLogout={handleLogout}/>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default Header;
